import React, { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import type { FC, RefObject } from 'react';

import type { DocNode } from '@atlaskit/adf-schema';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { AnnotationProviders } from '@atlaskit/editor-common/types';
import type { RendererActions } from '@atlaskit/renderer/actions';
import { AnnotationsWrapper } from '@atlaskit/renderer';
import { RendererActionsContext, WithRendererActions } from '@atlaskit/renderer/actions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import {
	InlineCommentQueryParamsListener,
	SelectionComponent,
	HoverComponent,
	ViewComponent,
} from '@confluence/annotation-provider-inline-comments';
import { InlineCommentLinkHandler } from '@confluence/inline-comments-link-handler';
import {
	useDocumentUpdateStatus,
	useDocumentUpdateStatusDispatch,
	useAnnotations,
	useAnnotationsDispatch,
} from '@confluence/annotation-provider-store';
import { RendererActionsListener } from '@confluence/renderer-actions';
import { usePageState } from '@confluence/page-context';
import {
	MissedPubSubCommentEventsHandler,
	setUpRendererInlineCommentsPubSub,
	removeRendererInlineCommentsPubSub,
	CommentEventType,
	type MissedCommentsResult,
} from '@confluence/comments-pubsub';
import { ContentRefetchContext, ContentRefetchHandler } from '@confluence/content-body';
import { useInlineComments } from '@confluence/inline-comments-hooks';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useSessionData } from '@confluence/session-data';

type InlineCommentsPubSubHandler = {
	contentId: string;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	updateDocument: (newADF: JSONDocNode) => void;
	rendererActions: RendererActions;
	isNestedRender?: boolean;
};

const InlineCommentsPubSubHandler: FC<InlineCommentsPubSubHandler> = ({
	contentId,
	contentType,
	updateDocument,
	rendererActions,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setDocumentUpdated } = useDocumentUpdateStatusDispatch();
	const { setRefetchContent } = useContext(ContentRefetchContext);
	const { documentNeedsUpdate, publishedDocumentVersion } = useDocumentUpdateStatus();
	const { setDocumentNeedsUpdate } = useDocumentUpdateStatusDispatch();
	const { userId } = useSessionData();
	const [, { updateUnresolvedInlineComment }] = useInlineComments();
	const [, { updateUnreadCommentsOnFirstRender }] = useUnreadInlineComments();

	const subscribeToPubSub = useCallback(
		() =>
			setUpRendererInlineCommentsPubSub({
				contentId,
				currentUserId: userId,
				contentType,
				rendererActions,
				updateDocument,
				updateUnresolvedInlineComment,
				setContentNeedsUpdate: setDocumentNeedsUpdate,
				needsContentUpdate: Boolean(documentNeedsUpdate),
				publishedDocumentVersion,
				createAnalyticsEvent,
			}),
		// We only need to respond to values that CAN change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			contentId,
			userId,
			contentType,
			rendererActions,
			documentNeedsUpdate,
			publishedDocumentVersion,
		],
	);

	const unsubscribeFromPubSub = useCallback(
		() =>
			removeRendererInlineCommentsPubSub({
				contentId,
				contentType,
			}),
		[contentId, contentType],
	);

	const handleFetchContentVersionEvent = useCallback(() => {
		setDocumentNeedsUpdate(true);
	}, [setDocumentNeedsUpdate]);

	const handleEventFetchSuccess = useCallback(
		(res: MissedCommentsResult) => {
			// TODO: Parse the events returned for 'resolved', 'unresolved', and 'delete' when they are added
			//       for now we just reload the content when the user comes back
			// For now all we have is create events, which means we will miss things, but only update if a new ones were created
			if (res.commentEvents.length > 0) {
				setDocumentUpdated(true);
				setRefetchContent(true);
				updateUnreadCommentsOnFirstRender(true);
			}
		},
		[setRefetchContent, setDocumentUpdated, updateUnreadCommentsOnFirstRender],
	);

	if (!userId) {
		return null;
	} else {
		return (
			<MissedPubSubCommentEventsHandler
				contentId={contentId!}
				eventType={CommentEventType.INLINE}
				subscribeToPubSubEvents={subscribeToPubSub}
				unsubscribeFromPubSubEvents={unsubscribeFromPubSub}
				onEventFetchSuccess={handleEventFetchSuccess}
				onFetchContentVersionEvent={handleFetchContentVersionEvent}
				inactiveInterval={0}
				returnInterval={0}
			/>
		);
	}
};

type WithAnnotationsWrapperIfEnabledProps = {
	annotationProvider: AnnotationProviders | null | undefined;
	rendererRef: RefObject<HTMLDivElement>;
	adfDocument: DocNode;
	children: JSX.Element;
	isNestedRender?: boolean;
	updateDocument: (newADF: JSONDocNode) => void;
	lastModifiedDate?: string | null;
};

type BaseProps = WithAnnotationsWrapperIfEnabledProps & WithFlagsProps;

const WithAnnotationsWrapperIfEnabledComponent: FC<BaseProps> = ({
	flags,
	annotationProvider,
	rendererRef,
	adfDocument,
	updateDocument,
	isNestedRender = false,
	children,
	lastModifiedDate,
}: BaseProps) => {
	const pageContainsBodiedMacrosRef = useRef(false);
	const { setDocumentUpdated, setDocumentNeedsUpdate, setPublishedDocumentVersion } =
		useDocumentUpdateStatusDispatch();
	const { refetchContent, setRefetchContent } = useContext(ContentRefetchContext);
	const [{ contentId, contentType }] = usePageState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		selectionProps,
		viewProps,
		hoverProps,
		showCreateComponent,
		showHighlightActions,
		selectionOptions,
		stepGenerationError,
	} = useAnnotations();

	const {
		setShowHighlightActions,
		setShowCreateComponent,
		setSelectionOptionsForCreateEditor,
		handleSaveSuccess,
		handleClose,
	} = useAnnotationsDispatch();

	const [, { updateUnreadCommentsOnFirstRender }] = useUnreadInlineComments();

	const handleOnRefetchComplete = useCallback(
		(versionNumber?: number | null) => {
			setRefetchContent(false);
			setDocumentUpdated(true);
			setDocumentNeedsUpdate(false);
			updateUnreadCommentsOnFirstRender(true);
			void flags.hideAllFlags();

			if (versionNumber) {
				setPublishedDocumentVersion(versionNumber);
			}

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'refetched',
					actionSubject: 'content',
					objectId: contentId,
					contentType,
					source: 'viewPageScreen',
				},
			}).fire();
		},
		[
			setRefetchContent,
			setDocumentUpdated,
			createAnalyticsEvent,
			contentId,
			contentType,
			setDocumentNeedsUpdate,
			flags,
			setPublishedDocumentVersion,
			updateUnreadCommentsOnFirstRender,
		],
	);

	// We need to check that there are page properties macros or excerpt macros on the page
	// so we can hide the delete option which causes weird issues in the document
	useEffect(() => {
		const bodiedMacroElem = document.querySelector('.ak-renderer-extension');
		pageContainsBodiedMacrosRef.current = Boolean(bodiedMacroElem);
	}, []);

	return annotationProvider ? (
		<RendererActionsContext>
			<WithRendererActions
				render={(actions) => {
					return (
						<Fragment>
							<AnnotationsWrapper
								rendererRef={rendererRef}
								adfDocument={adfDocument}
								annotationProvider={annotationProvider}
								isNestedRender={isNestedRender}
							>
								{selectionProps && !isNestedRender && (
									<SelectionComponent
										{...selectionProps}
										showCreateComponent={showCreateComponent}
										showHighlightActions={showHighlightActions}
										selectionOptions={selectionOptions}
										stepGenerationError={stepGenerationError}
										setSelectionOptionsForCreateEditor={setSelectionOptionsForCreateEditor}
										handleSaveSuccess={handleSaveSuccess}
										handleClose={handleClose}
										setShowCreateComponent={setShowCreateComponent}
										setShowHighlightActions={setShowHighlightActions}
										contentType={contentType}
										lastModifiedDate={lastModifiedDate}
									/>
								)}
								{hoverProps && !isNestedRender && <HoverComponent {...hoverProps} />}
								{viewProps && !isNestedRender && (
									<ViewComponent
										{...viewProps}
										pageContainsBodiedMacros={pageContainsBodiedMacrosRef.current}
									/>
								)}
								{children}
							</AnnotationsWrapper>
							<InlineCommentQueryParamsListener />
							<RendererActionsListener actions={actions} />
							<InlineCommentsPubSubHandler
								contentId={contentId!}
								contentType={contentType}
								updateDocument={updateDocument}
								rendererActions={actions}
							/>
							<ContentRefetchHandler
								contentId={contentId}
								shouldRefetch={refetchContent}
								refetchImmediately
								onRefetchComplete={handleOnRefetchComplete}
							/>
							<InlineCommentLinkHandler />
						</Fragment>
					);
				}}
			/>
		</RendererActionsContext>
	) : (
		<RendererActionsContext>{children}</RendererActionsContext>
	);
};

export const WithAnnotationsWrapperIfEnabled = withFlags(WithAnnotationsWrapperIfEnabledComponent);
